import React from "react";

import { IoIosRemoveCircleOutline } from "react-icons/io";

// CSS File 
import '../../../assets/css/Toasters.css';

const ItemRemovedToaster = ({item}) => {

    return (

        <div className="added-checklist-section-component-container">

            <div className="added-checklist-section-container">

                <div className="added-checklist-section-tick-container">

                    <IoIosRemoveCircleOutline className="removed-checklist-section-tick" />
                    
                </div>

                <div className="added-checklist-section-text-container">

                    <div>
                        <p> {item}  </p>
                    </div>

                </div>

            </div>

        </div>
    )
}

export default ItemRemovedToaster