import React from "react";
import { RiErrorWarningLine } from "react-icons/ri";
import '../../../assets/css/Toasters.css';

const DeletePowerBiReportsToaster = ({ onConfirm, onCancel, reportName }) => {
  return (
    <div className="login-toaster-component-container">
      <div className="confirm-delete-Ms-powerpoint-toaster-container">
        <div className="logins-tick-container">
          <RiErrorWarningLine className="confirm-delete-Ms-powerpoint-toaster-icon" />
        </div>
        <div className="logins-text-container">
          <div>
            <h4 style={{ color: 'red' }}>Confirm Delete MS Power Bi Report</h4>
          </div>
          <div className="logins-text-container">
            <p>Are you sure you want to delete the Power BI report ? This action cannot be undone.</p>
          </div>
        </div>
        <div className="delete-ms-power-point-link-button-container">
          <div>
            <button className="confirm-button" onClick={onConfirm}>Yes</button>
          </div>
          <div>
            <button className="cancel-button" onClick={onCancel}>No</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DeletePowerBiReportsToaster;