import React from "react";

import { SiTicktick } from "react-icons/si";

import { LiaSpinnerSolid } from "react-icons/lia";

// CSS File 
import '../../assets/css/Toasters.css';

const GenericSuccessToaster = ({heading, message}) => {

    return (

        <div className="login-toaster-component-container">

            <div className="login-toaster-container">

                <div className="logins-tick-container">
                    <SiTicktick className="login-tick" />
                </div>

                <div className="logins-text-container">

                    <div>
                        <h2> {heading}</h2>
                    </div>

                    <div className="logins-text-container">
                        <p>One moment. {message}</p>
                    </div>

                </div>

                <div>
                    <div>
                        <LiaSpinnerSolid className="lia-spin-solid-icon"/>
                    </div>
                </div>

                

            </div>

        </div>
    )
}

export default GenericSuccessToaster