import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import axios from "axios";

import blueKbcLogo from '../../../assets/images/branding/blue-kbc-logo.png';

//icons
import { FaRegEye } from 'react-icons/fa';
import { MdDeleteOutline } from "react-icons/md";
import { FaUserEdit } from "react-icons/fa";
import { AiOutlineUserDelete } from "react-icons/ai";
import { LiaSpinnerSolid } from "react-icons/lia";
import { CiEdit } from "react-icons/ci";


//toaster 
import ConfirmDeleteToasterComponent from "../../toasterscomponents/deleteToasters/ConfimDeleteToasterComponent";


// styles - css 
import '../../../assets/css/DataAnalytics.css';
import GenericSuccessToaster from "../../toasterscomponents/GenericSuccessToaster";
import EditPowerBiReportLink from "../Toasters/EditPowerBiReportLink";
import DeletePowerBiReportsToaster from "../Toasters/DeletePowerBiReport";

const ViewPowerBiLinks = () => {

  const [isLoading, setIsLoading] = useState(true);
  const [companies, setCompanies] = useState([]);
  const [selectedCompanyUsers, setSelectedCompanyUsers] = useState([]);
  const [showDeleteToaster, setShowDeleteToaster] = useState(false);

  const [showSuccess, setShowSuccess] = useState(false)

  const [showUnassignUserchecklisttoaster,setShowUnassignUserChecklistToaster] = useState(false);

  const [reportToDelete, setReportToDelete] = useState(null);


  const [companyToDelete, setCompanyToDelete] = useState(null);
  const[unassignChecklistUser, setUnassignChecklist] = useState(null)

  const [showEditToaster, setShowEditToaster] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);


  const [selectedUser, setSelectedUser] = useState(null);

  const [editedPowerBiSuccessfully, setEditedPowerBiSuccessfully] = useState(false);

  

  const navigate = useNavigate();

  const [powerBiReports, setPowerBiReports ] = useState([])

  

  const fetchPowerBiReportLinks = async () => {

    setIsLoading(true);
    try {
      const response = await axios.get('/api/rows/data-analytics/view-power-bi-reports');
      setPowerBiReports(response.data);
    } catch (error) {
      console.error('Error fetching Power BI Reports:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {   

    fetchPowerBiReportLinks();
    
  }, []);

  const fetchUsersForCompany = async (companyId) => {
    setIsLoading(true);
    try {
      const response = await axios.get(`/api/users/company/${companyId}`);
      console.log(`Fetched users for company ${companyId}:`, response.data);
      setSelectedCompanyUsers(response.data);
    } catch (error) {
      console.error('Error fetching users for company:', error);
      setSelectedCompanyUsers([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleNavigateBack = () => {
    navigate('/data-analytics');
  };

  

  const handleConfirmDelete = async () => {
    if (reportToDelete) {
      try {
        await axios.delete(`/api/rows/delete-analytics/power-bi-report/${reportToDelete.power_bi_id}`);
        // Refresh the list of reports
        await fetchPowerBiReportLinks();
        setShowDeleteToaster(false);
        setReportToDelete(null);
        // Optionally, show a success message

        setShowSuccess(true)

        setTimeout(() => {
          setShowSuccess(false);
      }, 2000);

      } catch (error) {
        console.error('Error deleting Power BI report:', error);
        // Optionally, show an error message
      }
    }
  };
  

  
  const handleUnassignChecklist = async (userId, checklist_id) => {
    
    try {
      const response = await axios.post('/api/unassign_user_from_checklist', { userId, checklist_id });
      if (response.status === 200) {
        await fetchUsersForCompany(selectedCompanyUsers[0]?.companyId);
      } else {
        console.error('Error unassigning user from checklist:', response.data.error);
      }
    } catch (error) {
      console.error('Error unassigning user from checklist:', error.response ? error.response.data.error : error.message);
    }
  };

  

  const handleEditUser = (user) => {
    setSelectedUser(user);
    setShowEditToaster(true);
  };

  const handleEditReport = (report) => {
    setSelectedReport(report);
    setShowEditToaster(true);
  };

  const handleCloseEditToaster = () => {
    setShowEditToaster(false);
    setSelectedReport(null);
  };

  const handleSaveEdit = async (updatedReport) => {

    try {
      await axios.put(`/api/rows/edit-analytics/power-bi-report/${updatedReport.power_bi_id}`, updatedReport);
      
      // Refresh the list of reports
      await fetchPowerBiReportLinks();
      
      setShowEditToaster(false);
      setSelectedReport(null);
      // Show a success message (you can implement this using a toast notification library)

      setEditedPowerBiSuccessfully(true)

      setTimeout(() => {

        setEditedPowerBiSuccessfully(false)
        
    }, 2500);

    } catch (error) {
      console.error('Error updating Power BI report:', error);
      // Show an error message
    }
  };

  
  const handleCancelDelete = () => {
    setShowDeleteToaster(false);
    setReportToDelete(null);
  };

  const handleDelete = (report) => {
    setReportToDelete(report);
    setShowDeleteToaster(true);
  };

  const handleNavigate  = () => {

    navigate('/data-analytics-add-MS-Power-Bi-Link')
  }

  

  return (
    <div className="component-container">
      <div className="create-checklist-component-container">
        <div className="create-company-text-container">
          <div>
            <h1>View Power Bi Link</h1>
            <p>To view, edit, and delete MS Power Bi Reports.</p>
          </div>
          <div>
            <img src={blueKbcLogo} alt="white-kbc-logo" className="side-menu-logo" />
          </div>
        </div>

        <div className="navigate-back-button-main-container">
          <div className="navigate-back-button-container">
            <button className="navigate-back-button" onClick={handleNavigateBack}>Back</button>
          </div>
        </div>

        <div className="view-checklist-table-container">
          <div className="view-checklist-table-table-container">
            <div>
              <h2>Power Bi Reports</h2>
            </div>

            {isLoading ? (
              <div className="loading-spinner-container">
                <LiaSpinnerSolid className="lia-spin-solid-icon" />
              </div>
            ) : (
              <div className="view-checklist-checklist-table-container"> 
                <table className="view-checklist-table">

                  <colgroup>
                    {/* Remove whitespace between col elements */}
                    <col style={{width: "250px"}}/>{/* Delete icon column */}
                    <col style={{width: "300px"}}/>{/* Delete icon column */}
                    <col style={{width: "150px"}}/>{/* Delete icon column */}
                  
                  </colgroup>
                  
                  <thead>
                    <tr>
                      <th className="table-header">Power Bi ID</th>
                      <th className="table-header">Report Name</th>
                     
                      <th className="table-header">Date Created</th>
                      <th className="table-header">Edit</th>
                      <th className="table-header">Delete Link</th>
                    </tr>
                  </thead>
                  <tbody className="view-table-body">
                    {powerBiReports.length === 0 ? (
                      <tr>
                        <td colSpan="6">

                          <div>
                            <p>No Power Bi reports. Click 'Add Power Bi' button to add MS Power Bi reports</p>
                          </div>

                          <div>
                            <button onClick={handleNavigate} className='confirm-button' type="button">Add Power Bi</button>
                          </div>
                          
                        </td>
                      </tr>
                    ) : (
                      powerBiReports.map((powerBiReport, index) => (
                        <tr key={index}>
                          <td>{powerBiReport.power_bi_id}</td>
                          <td>{powerBiReport.power_bi_name}</td>
                          
                          <td>{new Date(powerBiReport.date_created).toISOString().split('T')[0]}</td>


                          <td>
                            <CiEdit 
                              className='delete-checklist-submition-icon'
                              onClick={() => handleEditReport(powerBiReport)}
                            
                            />
                          </td>
                          <td>
                            <MdDeleteOutline 
                              className='delete-checklist-submition-icon'
                              onClick={() => handleDelete(powerBiReport)}
                            />
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>

        {selectedCompanyUsers.length > 0 && (
          <div className="view-users-container">
            <div>
              <h3>Users for Selected Company</h3>
            </div>

            {isLoading ? (
              <div className="loading-spinner-container">
                <LiaSpinnerSolid className="lia-spin-solid-icon" />
              </div>
            ) : (
              <div className='view-checklist-checklist-table-container'>
                <table className="view-checklist-table">
                  <colgroup>
                    <col style={{width: "200px"}} />
                    <col style={{width: "200px"}} />
                    <col style={{width: "200px"}} />
                    <col style={{width: "300px"}} />
                    <col style={{width: "300px"}} />
                    <col style={{width: "300px"}} />
                    <col style={{width: "300px"}} />
                    <col style={{width: "150px"}} />
                    <col style={{width: "150px"}} />
                  </colgroup>
                  <thead>
                    <tr>
                      <th className="table-header">ID</th>
                      <th className="table-header">Name</th>
                      <th className="table-header">Surname</th>
                      <th className="table-header">ID Number</th>
                      <th className="table-header">Company Name</th>
                      <th className="table-header">User Email</th>
                      <th className="table-header">Assigned Checklists</th>
                      <th className="table-header">User Role</th>
                      <th className="table-header">Edit User</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedCompanyUsers.map((user, checklist_id,index) => (
                      <tr key={index}>
                        <td className="view-user-td">{user.id}</td>
                        <td className="view-user-td">{user.userName}</td>
                        <td className="view-user-td">{user.userSurname}</td>
                        <td className="view-user-td">{user.userIdNo}</td>
                        <td className="view-user-td">{user.companyName}</td>
                        <td className="view-user-td">{user.userEmail}</td>
                        <td className="view-user-td">
                          {user.assignedChecklists ? (
                            <div className="view-user-assigned-checklist-container">
                              <ul className="view-user-assigned-list-container">
                                {user.assignedChecklists.split(', ').map((checklist, checklist_id, index) => (
                                  <li className="view-user-assigned-list-container" key={index}>
                                    <div className="view-user-assigned-list">
                                      <div>{checklist}</div>
                                      <div>
                                        <AiOutlineUserDelete 
                                          className='delete-checklist-submition-icon' 
                                          onClick={() => handleUnassignChecklist(user.id, checklist_id)}
                                        />
                                      </div>
                                    </div>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          ) : (
                            'User not assigned to checklists'
                          )}
                        </td>
                        <td className="view-user-td">{user.userRole}</td>
                        <td className="view-user-td">
                          <FaUserEdit  
                            className='delete-checklist-submition-icon' 
                            onClick={() => handleEditUser(user)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        )}
      </div>

      

      {/* Toasters */}
      {showUnassignUserchecklisttoaster && (
        <ConfirmDeleteToasterComponent
          onConfirm={handleConfirmDelete}
          onCancel={handleCancelDelete}
          confirmDeleteHeading='Unassign User'
          deleteUserAndCompanyWarning='Warning! Unassigining a user from a checklist will deny them access from compleiting the checklist'
        />
      )}

    {showEditToaster && selectedReport && (
        <EditPowerBiReportLink
          report={selectedReport}
          onSave={handleSaveEdit}
          onCancel={handleCloseEditToaster}
        />
      )}

      {editedPowerBiSuccessfully && (

        <GenericSuccessToaster 
          heading={'Edited Succesfully'}
          message={'While we redirect you to the next page.'}
        />


      )}

      {showDeleteToaster && reportToDelete && (
        <DeletePowerBiReportsToaster
          onConfirm={handleConfirmDelete}
          onCancel={handleCancelDelete}
          reportName={reportToDelete.power_bi_name}
        />
      )}

      {showSuccess &&

        <GenericSuccessToaster
          heading={'Succesfully Deleted Power Bi Report'}
          message={' As we redirect you. '}
        />
      }

      
      
    </div>
  );
};

export default ViewPowerBiLinks;
