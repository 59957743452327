import React from "react";
import { Link } from "react-router-dom";



import { SiTicktick } from "react-icons/si";

/* This toaster appears when the user successfully creates a checklist */

const CreatedSuccessfullToaster =  ({successToasterTitle, successToasterMessage}) => {

    return (
        <div className="login-toaster-component-container">

            <div className="login-toaster-container">

                <div className="logins-tick-container">
                    <SiTicktick className="login-tick" />
                </div>

                <div className="logins-text-container">

                    <div>
                        <h2> {successToasterTitle}</h2>
                    </div>

                    <div className="logins-text-container">
                        <p>{successToasterMessage}</p>
                    </div>

                </div>

                <div className="loading-circle">
                
                </div> {/* Replaced the "O" with the loading circle */}

            </div>

        </div>
    )
}

export default CreatedSuccessfullToaster;
