/* ----------- API Config file -------------

    - This file consisits of the domiains that the applicaiton connects to on different enviormnets */

//const API_BASE_URL = 'https://spcdev.devkbcsafety.com'; // staging url

const API_BASE_URL = 'https://spcstaging.devkbcsafety.com'; // development URL 

//const API_BASE_URL = 'http://localhost:5000'; // local host

export default API_BASE_URL;