/* --------------- Loading Component --------------------- 

    - Loading component appears when application is loading to load the applicaiotn in pieces to improve perfomance*/

// ---------- React Components ---------------
import React from "react";

// --------------- Style ---------------------

// styles - css
import '../../components/loadingComponents/loadingComponent.css';

//styles - branding images 
import blueKbcLogo from '../../assets/images/branding/blue-kbc-logo.png'; 

// ------------------- Icon -----------------------

import { LiaSpinnerSolid } from "react-icons/lia";

const AppLoadingComponents = ({loading_title_name}) => {

    return (
        <div className="loading-component-main-container">

            <div className="loading-toaster-container">

                

                    <div>

                        <div>
                            <img src={blueKbcLogo} alt="white-kbc-logo" className="side-menu-logo" />
                        </div>

                    </div>

                    <div>
                        <div>
                            <h3>{loading_title_name}</h3>
                        </div>
                    </div>
                
             

                <div>
                    <div>
                        <LiaSpinnerSolid className="lia-spin-solid-icon"/>
                    </div>
                </div>

            </div>
            
        </div>
    )
}

export default AppLoadingComponents